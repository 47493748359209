import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import { getFromConfig, getHrefLang, getLocalizedUrl } from '../utils/localeURL'

export interface BreadCrumbsProps {
  default?: boolean
  theme?: string
  center?: boolean
  items: [BreadCrumb]
}

export interface BreadCrumb {
  displayText: string
  path: string
}

export default function BreadCrumbs(props: BreadCrumbsProps) {
  const { items, theme, center } = props

  const localizedHomepageUrl = `/${getFromConfig(process.env.LOCALE ?? `cs`, `urlPrefix`, `PuxDesignGatsby`)}`

  const home = (
    <Link
      className="breadcrumbs-item"
      hrefLang={getHrefLang(localizedHomepageUrl)}
      to={localizedHomepageUrl}
      key={`home`}
    >
      PUXDESIGN
    </Link>
  )

  return (
    <div className={`breadcrumbs-wrap ${theme ? theme : ``} ${center ? `center` : ``}`}>
      <div className="pux-container">
        <div className="breadcrumbs">
          {props.default && (
            <>
              {home}
              <Link
                className="breadcrumbs-item"
                hrefLang={getHrefLang(items[0].path)}
                to={`${getLocalizedUrl(items[0].path)}`}
              >
                {items[0].displayText}
              </Link>
            </>
          )}
          {!props.default &&
            items?.map((item, index) => {
              if (index === 0) {
                return (
                  <>
                    {home}
                    <Link
                      className="breadcrumbs-item"
                      hrefLang={getHrefLang(item.path)}
                      to={`${getLocalizedUrl(item.path)}`}
                      key={index}
                    >
                      {item.displayText}
                    </Link>
                  </>
                )
              } else {
                return (
                  <Link
                    className="breadcrumbs-item"
                    hrefLang={getHrefLang(item.path)}
                    to={`${getLocalizedUrl(item.path)}`}
                    key={index}
                  >
                    {item.displayText}
                  </Link>
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}
