import React from "react"
import { MediaSelector } from "../../types/widget.types";

interface PictureProps extends MediaSelector {
    alt: string
    lazy?: boolean | undefined
    onLoad?: () => void | undefined
}

export default function Picture({ puxMediaSelectorImage, alt, lazy, onLoad }: PictureProps) {
    const defaultImg = puxMediaSelectorImage.resizePaths[0] == undefined ? '' : puxMediaSelectorImage.resizePaths[0].replace(/\s/g,"%20")
    const tablet = puxMediaSelectorImage.resizePathsTablet[0] == undefined ? '' : puxMediaSelectorImage.resizePathsTablet[0].replace(/\s/g,"%20")
    const mobile = puxMediaSelectorImage.resizePathsMobile[0] == undefined ? '' : puxMediaSelectorImage.resizePathsMobile[0].replace(/\s/g,"%20")
    
    return (
        <picture>
            <source media="(min-width:1200px)" srcSet={defaultImg} />
            <source media="(min-width:768px)" srcSet={tablet} />
            <source media="(min-width:320px)" srcSet={mobile} />
            <img loading={lazy ? 'lazy' : undefined} src={defaultImg} alt={alt} onLoad={onLoad ? () => onLoad() : undefined}/>
        </picture>
    )
}